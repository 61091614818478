<template>
    <div class="column full-height">
        <div class="section boxed header">
          <div class="row items-center justify-between q-py-lg">
              <router-link to="/"><img alt="Calcola la superficie commerciale con un click" src="images/logo_areaplan.png" style="height: 100px; max-width: 80vw; object-fit: contain"/></router-link>
              <q-btn flat :label="$t('Accedi/registrati')" class="col-auto text-weight-bold" icon-right="account_circle" to="/auth/login"></q-btn>
          </div>
        </div>
        <div class="row col">
            <div class="bg-secondary col-md-4 gt-sm q-px-lg">
                <h5 class="text-dark-gray q-mt-xl q-mx-lg">{{ $t('auth.free_trial') }}</h5>
                <div class="flex">
                    <div class="flex text-dark-gray q-mx-lg no-wrap">
                        <q-icon name="fas fa-check-circle" color="accent"></q-icon>
                        <div class="q-ml-sm">
                            <div class="text-weight-medium">{{ $t('auth.bullet_1') }}</div>
                            <div class="">{{ $t('auth.bullet_1_caption') }}</div>
                        </div>
                    </div>
                    <div class="flex text-dark-gray q-mx-lg no-wrap q-mt-lg">
                        <q-icon name="fas fa-check-circle" color="accent"></q-icon>
                        <div class="q-ml-sm">
                            <div class="text-weight-medium">{{ $t('auth.bullet_2') }}</div>
                            <div class="">{{ $t('auth.bullet_2_caption') }}</div>
                        </div>
                    </div>
                    <div class="flex text-dark-gray q-mx-lg no-wrap q-mt-lg">
                        <q-icon name="fas fa-check-circle" color="accent"></q-icon>
                        <div class="q-ml-sm">
                            <div class="text-weight-medium">{{ $t('auth.bullet_3') }}</div>
                            <div class="">{{ $t('auth.bullet_3_caption') }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col flex q-ma-xl">
                <div class="q-gutter-sm form-container">
                    <h1 class="text-h2 text-accent text-weight-bold q-my-none">{{ $t('auth.start_free_trial') }}</h1>
                    <h2 class="text-h5 text-grey-7 text-weight-bolder">{{ $t('registrazione') }}</h2>
                    <q-input
                            color="light-gray"
                            label-color="light-gray"
                            square
                            outlined
                            v-model="formData.name"
                            :label="$t('generals.full_name')"
                            :rules="[val => !!val || $t('validation.required', { field: $t('generals.full_name')})]"
                            lazy-rules
                    />
                    <q-input
                            color="light-gray"
                            label-color="light-gray"
                            square
                            outlined
                            v-model="formData.company"
                            :label="$t('generals.company')"
                            :rules="[val => !!val || $t('validation.required', { field: $t('generals.company')})]"
                            lazy-rules
                    />
                    <q-input
                            color="light-gray"
                            label-color="light-gray"
                            square
                            outlined
                            v-model="formData.email"
                            :label="$t('generals.email')"
                            type="email"
                            :rules="[val => !!val || $t('validation.required', { field: $t('generals.email')}), val => isValidEmail(val)]"
                            lazy-rules
                    />
                    <q-input
                            color="light-gray"
                            label-color="light-gray"
                            square
                            outlined
                            v-model="formData.password"
                            :label="$t('generals.password')"
                            :type="hidePassword ? 'password' : 'text'"
                            :rules="[
                                val => !!val || $t('validation.required', { field: $t('generals.password')}),
                                val => val.length >= 8 || $t('validation.min', { field: $t('generals.password'), size: 8})
                            ]"
                            lazy-rules
                    >
                        <template v-slot:append>
                            <q-icon
                                    :name="hidePassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                                    class="cursor-pointer"
                                    @click="hidePassword = !hidePassword"
                            />
                        </template>
                    </q-input>
                    <q-input
                            color="light-gray"
                            label-color="light-gray"
                            square
                            outlined
                            v-model="formData.password_confirmation"
                            :label="$t('generals.password_confirmation')"
                            :type="hidePasswordConfirm ? 'password' : 'text'"
                            :rules="[val => !!val || $t('validation.required', { field: $t('generals.password_confirmation')}), val => passwordMatches(val)]"
                            lazy-rules
                    >
                        <template v-slot:append>
                            <q-icon
                              :name="hidePasswordConfirm ? 'fas fa-eye-slash' : 'fas fa-eye'"
                              class="cursor-pointer"
                              @click="hidePasswordConfirm = !hidePasswordConfirm"
                            />
                        </template>
                    </q-input>
                    <div class="flex no-wrap items-start">
                        <q-checkbox dense size="lg" v-model="formData.terms_accepted" />
                        <div class="q-ml-md">
                            <div class="flex" v-html="$t('auth.accept_privacy_and_terms_conditions')"></div>
                            <div class="q-mt-sm flex" v-html="$t('auth.cookies')"></div>
                        </div>
                    </div>
                    <div class="q-mt-md text-size-sm text-caption">
                      <div class="row q-gutter-lg items-center q-py-xs">
                        <q-btn size="lg" color="accent" class="q-px-lg text-weight-bolder" @click="submit">{{ $t('auth.create_account') }}</q-btn>
                        <div class="text-red-4 text-bold text-body1">ATTENZIONE, il software funziona solo da DESKTOP</div>
                      </div>
                      <div class="q-mt-md text-light-gray">
                          {{ $t('auth.already_have_account') }}
                          <a href="/auth/login" class="text-accent">{{ $t('auth.login') }}</a>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import User from 'src/store/models/User'

export default {
  name: 'Signup',

  data () {
    return {
      hidePassword: true,
      hidePasswordConfirm: true,
      formData: {
        name: '',
        company: '',
        email: '',
        password: '',
        password_confirmation: '',
        terms_accepted: false
      }
    }
  },

  methods: {
    isValidEmail (email) {
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
      return emailPattern.test(email) || this.$t('validation.regex', { field: this.$t('generals.email') })
    },
    passwordMatches (confirmation) {
      return confirmation === this.formData.password || this.$t('validation.confirmed', { field: this.$t('generals.password') })
    },
    submit () {
      this.$q.loading.show()
      User.$register(this.formData).then((response) => {
        this.$router.push({ name: 'projects', params: { newUser: true } })
      }).finally(() => {
        this.$q.loading.hide()
      })
    }
  }
}
</script>

<style scoped lang="scss">
    .form-container {
        max-height: 100vh;
        overflow: auto;
    }
    .app-name {
        margin-top: 80px;
        margin-bottom: 80px;
    }
    body.screen--xl,body.screen--lg {
        .form-container {
            width: 50%;
        }
    }
    .section.boxed {
    display: flex;
    > div {
      width: 90vw;
      margin: auto;
    }
  }
</style>
