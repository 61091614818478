import { render, staticRenderFns } from "./Signup.vue?vue&type=template&id=4c779ba4&scoped=true&"
import script from "./Signup.vue?vue&type=script&lang=js&"
export * from "./Signup.vue?vue&type=script&lang=js&"
import style0 from "./Signup.vue?vue&type=style&index=0&id=4c779ba4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c779ba4",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QIcon,QInput,QCheckbox});
